import React, { ReactElement } from "react";

export default function BullsEye({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="40"
            fill="none"
            viewBox="0 0 42 40"
            className={className}
        >
            <mask
                id="mask0_0_1558"
                style={{ maskType: "alpha" }}
                width="42"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#909090" d="M0 0H41.481V40H0z"></path>
            </mask>
            <g mask="url(#mask0_0_1558)">
                <path
                    fill="#000"
                    d="M34.607 2.26l-4.798 4.798.124.359.651 1.84-11.409 11.397a2.838 2.838 0 00-1.541-.459 2.867 2.867 0 00-2.86 2.86 2.87 2.87 0 002.86 2.863 2.873 2.873 0 002.863-2.863 2.83 2.83 0 00-.47-1.559l11.405-11.39 2.197.777 4.798-4.797-2.823-1-.997-2.826zm-.5 2.192l.557 1.571 1.572.558-2.92 2.917-1.572-.554-.555-1.57 2.918-2.922zm-16.473 4.36c-7.859 0-14.243 6.385-14.243 14.242 0 7.859 6.385 14.243 14.243 14.243 7.857 0 14.242-6.384 14.242-14.243 0-2.57-.696-5.091-2.014-7.299l-.31-.517-1.027.615.306.513a13.05 13.05 0 011.846 6.688c0 7.21-5.833 13.044-13.043 13.044-7.21 0-13.044-5.833-13.044-13.044 0-7.21 5.834-13.043 13.044-13.043 2.354 0 4.666.638 6.687 1.845l.513.31.615-1.031-.513-.31a14.25 14.25 0 00-7.302-2.014zm0 5.576c-4.779 0-8.667 3.889-8.667 8.667 0 4.778 3.888 8.665 8.667 8.665 4.777 0 8.665-3.888 8.665-8.665 0-1.04-.187-2.072-.553-3.046l-.213-.562-1.12.42.209.562c.315.84.478 1.73.478 2.626a7.458 7.458 0 01-7.466 7.466 7.458 7.458 0 01-7.467-7.466 7.46 7.46 0 0110.09-6.99l.563.213.42-1.124-.562-.212a8.667 8.667 0 00-3.044-.554zm0 7.006c.925 0 1.663.736 1.663 1.66 0 .926-.738 1.665-1.663 1.665-.926 0-1.661-.739-1.661-1.664 0-.925.735-1.66 1.66-1.66z"
                ></path>
            </g>
        </svg>
    );
}
