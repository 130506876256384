import React, { ReactElement } from "react";

export default function Pointer({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="40"
            fill="none"
            viewBox="0 0 42 40"
            className={className}
        >
            <mask
                id="mask0_0_1539"
                style={{ maskType: "alpha" }}
                width="42"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#494848" d="M0 0H41.481V40H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_1539)">
                <path d="M25.573 19.553h-1.012v-3.787a1.321 1.321 0 00-1.322-1.32.88.88 0 00-.88.88v4.253h-1.017V15.3a1.88 1.88 0 011.876-1.88 2.347 2.347 0 012.346 2.347l.01 3.787z"></path>
                <path d="M28.784 21.368h-1.017v-4.02a1.101 1.101 0 00-2.201 0v4.02h-1.013v-4.02a2.113 2.113 0 114.223 0l.008 4.02z"></path>
                <path d="M31.995 22.689h-1.017v-4.02a1.1 1.1 0 00-2.202 0v4.02h-1.008v-4.02a2.113 2.113 0 114.227 0v4.02zM18.49 33.653L10.294 21.14a2.915 2.915 0 014.73-3.395l2.135 2.722V6.718a2.602 2.602 0 115.205 0v8.305h-1.022V6.718a1.59 1.59 0 00-3.179 0V21.02a.823.823 0 01-1.47.506l-2.466-3.157a1.902 1.902 0 00-3.083 2.202l8.19 12.523-.846.56z"></path>
                <path d="M25.136 6.982h-1.013a4.381 4.381 0 00-8.759 0h-1.012a5.394 5.394 0 0110.784 0zM29.331 33.556l-.942-.366 2.29-5.997c.201-.524.304-1.08.303-1.643V22.69H32v2.861c-.001.692-.128 1.377-.375 2.022l-2.294 5.984z"></path>
                <path d="M30.462 39.461H17.327a1.532 1.532 0 01-1.528-1.528V34.41a1.532 1.532 0 011.528-1.554h13.135a1.531 1.531 0 011.533 1.533v3.522a1.531 1.531 0 01-1.533 1.55zm-13.135-5.579a.51.51 0 00-.511.515v3.523c0 .283.228.513.51.515h13.136c.285 0 .516-.23.516-.515v-3.523a.515.515 0 00-.516-.515H17.327z"></path>
            </g>
        </svg>
    );
}
