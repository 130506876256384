import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import { processList } from "@helpers/process";
export default function Process(): ReactElement {
    return (
        <section className="px-8 py-20 lg:py-40  main-container">
            <div className="mb-10 lg:mb-20">
                <h2 className=" text-4xl text-center mb-5 lg:mb-10">
                    Our Unique Client Acquisition Process
                </h2>
                <p className="text-base lg:text-xl text-center leading-[160%]">
                    At Torts.com, we've developed a highly effective client
                    acquisition process tailored specifically to empower lawyers
                    in growing their clientele.
                </p>
            </div>
            <div className="flex flex-col justify-center items-center lg:flex-row gap-16 lg:gap-10 ">
                {processList.map((item, index) => (
                    <div
                        key={item.title}
                        className={`flex  flex-col-reverse lg:flex-col  items-center lg:items-start lg:w-1/6 w-[300px] lg:h-[340px] ${styles["item"]} lg:pr-6 text-center lg:text-left`}
                    >
                        <span className="text-7xl font-bold hidden lg:block text-red-200 mb-10 ">
                            {index + 1}
                        </span>
                        <div className="md:h-[150px]">
                            <p className="lg:text-xl font-bold mb-4">
                                {item.title}
                            </p>
                            <p>{item.description}</p>
                        </div>
                        {item.icon}
                    </div>
                ))}
            </div>
        </section>
    );
}
