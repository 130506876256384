import Bars from "./svgs/Bars";
import BullsEye from "./svgs/BullsEye";
import Eye from "./svgs/Eye";
import Hammer from "./svgs/Hammer";
import Paper from "./svgs/Paper";
import Pointer from "./svgs/Pointer";
const iconsClass = "w-10 h-10 mt-auto mb-4 lg:mb-0";

export const processList = [
    {
        title: "Impressions",
        description: "We extensively advertise our websites.",
        icon: <Eye className={iconsClass} />,
    },
    {
        title: "Clicks",
        description: "We generate traffic to our diverse websites.",
        icon: <Pointer className={iconsClass} />,
    },
    {
        title: "Raw Leads",
        description: "We generate leads.",
        icon: <Bars className={iconsClass} />,
    },
    {
        title: "Qualified Leads",
        description:
            "We employ third-party data APIs and our call center to verify the leads.",
        icon: <BullsEye className={iconsClass} />,
    },
    {
        title: "Retainer",
        description: "We generate retainers using reputable intake centers.",
        icon: <Paper className={iconsClass} />,
    },
    {
        title: "Full Case",
        description:
            "This stage includes medical record retrieval and various verifications.",
        icon: <Hammer className={iconsClass} />,
    },
];
