import React, { ReactElement } from "react";

export default function Paper({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="40"
            fill="none"
            viewBox="0 0 42 40"
            className={className}
        >
            <mask
                id="mask0_0_1561"
                style={{ maskType: "alpha" }}
                width="42"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#909090" d="M0 0H41.481V40H0z"></path>
            </mask>
            <g
                fill="#000"
                fillRule="evenodd"
                clipRule="evenodd"
                mask="url(#mask0_0_1561)"
            >
                <path d="M27.098 7.16V1.531h1.063v5.627c0 .594.482 1.076 1.076 1.076h5.628V9.3h-5.628a2.14 2.14 0 01-2.14-2.14z"></path>
                <path d="M7 3.14A2.14 2.14 0 019.14 1h17.824a2.14 2.14 0 011.513.626L34.77 7.92c.401.401.627.945.627 1.513V36.86a2.14 2.14 0 01-2.14 2.14H9.14A2.14 2.14 0 017 36.86V3.14zm2.14-1.076c-.594 0-1.076.482-1.076 1.076v33.72c0 .594.482 1.076 1.076 1.076h24.117c.594 0 1.076-.482 1.076-1.076V9.433c0-.286-.114-.56-.315-.761l-6.293-6.293a1.076 1.076 0 00-.761-.315H9.14z"></path>
                <path d="M30 16.83L16.903 26 13 19.043l1.059-.472 3.254 5.8L29.269 16l.731.83z"></path>
            </g>
        </svg>
    );
}
