import React, { ReactElement } from "react";

export default function Bars({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="40"
            fill="none"
            viewBox="0 0 42 40"
            className={className}
        >
            <mask
                id="mask0_0_1550"
                style={{ maskType: "alpha" }}
                width="42"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#909090" d="M0 0H41.481V40H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_1550)">
                <path d="M8.999 18.194c-.646 0-1.17.526-1.17 1.17v9.054c0 .645.524 1.17 1.17 1.17h3.984c.646 0 1.17-.525 1.17-1.17v-9.053c0-.645-.524-1.17-1.17-1.17H9zm4.374 1.17v9.054a.39.39 0 01-.39.39H9a.39.39 0 01-.39-.39v-9.053a.39.39 0 01.39-.39h3.984a.39.39 0 01.39.39zM15.882 15.168c-.646 0-1.171.525-1.171 1.17v12.08c0 .645.525 1.17 1.17 1.17h3.985c.646 0 1.17-.525 1.17-1.17l.001-12.08c0-.645-.525-1.17-1.17-1.17H15.88zm4.375 1.17v12.08a.39.39 0 01-.39.39H15.88a.39.39 0 01-.39-.39v-12.08a.39.39 0 01.39-.39h3.985a.39.39 0 01.39.39zM22.766 12.143c-.645 0-1.17.525-1.17 1.17v15.105c0 .645.525 1.17 1.17 1.17h3.985c.645 0 1.17-.525 1.17-1.17V13.314c0-.646-.524-1.17-1.17-1.17h-3.985zm4.375 1.17v15.105a.39.39 0 01-.39.39h-3.985a.39.39 0 01-.39-.39V13.314a.39.39 0 01.39-.39h3.985a.39.39 0 01.39.39zM29.649 9.117c-.645 0-1.17.525-1.17 1.17v18.131c0 .646.525 1.17 1.17 1.17h3.986c.645 0 1.17-.524 1.17-1.17v-18.13c0-.646-.525-1.17-1.17-1.17h-3.986zm4.376 1.17v18.131a.39.39 0 01-.39.39h-3.986a.39.39 0 01-.39-.39v-18.13a.39.39 0 01.39-.39h3.985a.39.39 0 01.39.39z"></path>
                <path d="M38.745 31.792H6.181V5.262A1.26 1.26 0 004.926 4H3.254A1.26 1.26 0 002 5.263v29.474A1.26 1.26 0 003.254 36h35.492A1.26 1.26 0 0040 34.737v-1.683a1.26 1.26 0 00-1.255-1.262zm.418 2.946a.42.42 0 01-.418.42H3.254a.42.42 0 01-.418-.42V5.262a.42.42 0 01.418-.42h1.672c.23 0 .418.189.418.42v27.16c0 .117.094.211.209.211h33.192c.23 0 .418.19.418.42v1.685z"></path>
            </g>
        </svg>
    );
}
