import React, { ReactElement } from "react";

export default function Hammer({
    className,
}: {
    className: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="40"
            fill="none"
            viewBox="0 0 42 40"
            className={className}
        >
            <mask
                id="mask0_0_1567"
                style={{ maskType: "alpha" }}
                width="42"
                height="40"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#DD1F26" d="M0 0H41.481V40H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_1567)">
                <path d="M15.64 33.335a.53.53 0 01-.542-.522V31.66a.7.7 0 00-.715-.69H6.195a.7.7 0 00-.715.69v1.153a.53.53 0 01-.542.522.53.53 0 01-.542-.522V31.66c0-.953.805-1.734 1.8-1.734h8.182c.989 0 1.798.776 1.798 1.734v1.153a.528.528 0 01-.536.522z"></path>
                <path d="M17.394 35.974H3.314a.53.53 0 01-.543-.523v-1.47c0-.932.788-1.691 1.754-1.691h11.651c.966 0 1.754.76 1.754 1.691v1.47a.525.525 0 01-.536.523zM3.855 34.929h12.997v-.948a.66.66 0 00-.67-.646H4.526a.66.66 0 00-.67.646v.948zM36.045 35.974c-.659 0-1.279-.247-1.748-.7L19.183 20.705a.507.507 0 010-.738.554.554 0 01.765 0l15.114 14.57c.53.51 1.44.51 1.971 0a1.304 1.304 0 000-1.896L21.886 18.04a.507.507 0 010-.738.554.554 0 01.765 0l15.142 14.6a2.325 2.325 0 010 3.372c-.464.453-1.09.7-1.748.7z"></path>
                <path d="M15.742 24.62a.538.538 0 01-.38-.15.512.512 0 01-.011-.738L26.013 13.24a.553.553 0 01.766-.005.507.507 0 01.005.738L16.127 24.465a.533.533 0 01-.385.156zM9.598 18.82a.538.538 0 01-.38-.15.507.507 0 01-.005-.738L19.876 7.44a.553.553 0 01.765-.005.507.507 0 01.006.738L9.99 18.665a.56.56 0 01-.391.156z"></path>
                <path d="M14.624 27.771c-.262 0-.525-.096-.726-.285l-8.037-7.589a.982.982 0 01-.017-1.416l1.58-1.557a1.068 1.068 0 011.47-.016l8.031 7.589a.982.982 0 01.017 1.416l-1.58 1.557c-.196.199-.47.302-.738.302zm-7.97-8.59l7.97 7.53 1.52-1.498-7.97-7.53-1.52 1.498zM27.695 15.158c-.263 0-.526-.097-.727-.285l-8.031-7.584a.982.982 0 01-.017-1.416l1.58-1.557a1.068 1.068 0 011.47-.016L30 11.89a.982.982 0 01.017 1.416l-1.58 1.556c-.201.2-.47.296-.743.296zm-7.97-8.585l7.97 7.529 1.519-1.497-7.97-7.53-1.52 1.498z"></path>
            </g>
        </svg>
    );
}
